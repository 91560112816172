import React, { Component } from 'react';
import { Card, CardSubtitle, CardTitle,Button,CardText, CardBody, CardImg, CardFooter} from 'reactstrap'
import { withRouter } from '../Global/withRouter'
import { Auth } from 'aws-amplify'
class ProjectCard extends Component
{
    constructor(props)
    {
        super(props)
        // this.state = {
        //     projectID : '',
        //     project : {}
        // }

        this.navigateToProject = this.navigateToProject.bind(this);
    }

    // componentDidMount = async () => {
    //     this.setState((prevState,props) => ({
    //         project : props.project,
    //         projectID : props.project.projectID
    //     }))
    // }

    navigateToProject = async (projectID) =>
    {
        // 1. Check with the backend that this user is still allowed
        let theState = { 
            projectID: projectID,
            project : this.props.project,
            client : this.props.client,
            clientID : this.props.client.clientID,
            virtualWorld : this.props.project.virtualWorld
        }

        console.log(`ProjectCard-------`)
        console.dir(theState)
        console.log(`ProjectCard-------`)
        
        this.props.router.navigate(`/project/${projectID}`,
            {
                state:theState
            })

        
    }

    render () {
        const { client, project } = this.props
        return (
            
            // <Card>
            //     <CardHeader>
            //         {client.clientName}
            //     </CardHeader>
            //     <CardBody>

            //     { project.description }
            //     </CardBody>
            // </Card>
            <Card   className="projectCard" 
                    onClick={() => this.navigateToProject(project.projectID)}>
                <CardImg
                    alt="Card image cap"
                    src="https://picsum.photos/318/180"
                    top
                    width="200px"
                />
                <CardBody>
                    <CardTitle tag="h5">
                        {project.title}
                    </CardTitle>
                    <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                    >
                        {client.clientName}
                    </CardSubtitle>
                    <CardText>
                        { project.description }
                    </CardText>
                </CardBody>
                <CardFooter className="rightAlignFooter"> 
                    <Button>
                        View
                    </Button>
                </CardFooter>
            </Card>
        )
    }
}

export default withRouter(ProjectCard)