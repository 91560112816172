import axios, { AxiosInstance } from "axios";
import { Auth } from 'aws-amplify'
global.Buffer = global.Buffer || require('buffer').Buffer

  
    

    const instance = async () => {

      // axios.defaults.baseURL = 'https://api.example.com';
      // axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
      // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

      // let currentUser = await Auth.currentAuthenticatedUser({bypassCache: false})
      //         .then((user) => {
      //             return user;
      //         })
      //         .catch((err) => console.log(err));
      // DW:TODO:PROCESS.ENV
      let REACT_APP_PRIMARY_APIENDPOINT = 'https://api.ludaspace.com'
      return axios.create({
        baseURL: REACT_APP_PRIMARY_APIENDPOINT,//"http://localhost:3003/",
        timeout: 15000
      });
    }

    const authorisationHeaders = async () => {
      let session = await Auth.currentSession();
      let idToken = session.getIdToken();
      let jwtToken = idToken.getJwtToken();
      let bearer = `Bearer ${jwtToken}`

      // DW:TODO:PROCESS.ENV
      let REACT_APP_PROJECTSAPI_KEY = 'zGNpOX5j72721z8M52DRg6iEgMPfBnnK1wlzeLGA';
      return { 
        headers: 
        {
          "Content-type": "application/json",
          "Authorization" : bearer, 
          "x-api-key" : REACT_APP_PROJECTSAPI_KEY
        }
      }
    }

    const unAuthorisedHeaders = async () => {
      // DW:TODO:PROCESS.ENV
      let REACT_APP_PROJECTSAPI_KEY = 'zGNpOX5j72721z8M52DRg6iEgMPfBnnK1wlzeLGA';

      return { 
        headers: 
        {
          "Content-type": "application/json",
          "Authorization" : "Bearer unauthorized", 
          "x-api-key" : REACT_APP_PROJECTSAPI_KEY
        }
      }
    }
  
  
export {
  instance, authorisationHeaders,unAuthorisedHeaders
}
