

import { instance,authorisationHeaders, unAuthorisedHeaders } from "../HttpCommon";

type Project = {

};

type ProjectListResponse = {
  data: Project[];
};

class ProjectsService {


  async getAll() {
    // console.dir(process.env.REACT_APP_API_PROJECTSLIST)
    let req = await instance()
    // DW:TODO:PROCESS.ENV
    let REACT_APP_API_PROJECTSLIST = 'projects'
    let projectListUrl:string = (REACT_APP_API_PROJECTSLIST as string);
    return await req.get<ProjectListResponse>(projectListUrl,
                         await authorisationHeaders());
  }

  async getClientByClientID(clientID:string) {
  
    let request = await instance()
    // DW:TODO:PROCESS.ENV
    let REACT_APP_API_PROJECTSLIST = 'projects';
    let REACT_APP_API_CURRENTVERSION = '0100';
    let client = await request.post(`${REACT_APP_API_PROJECTSLIST}/${REACT_APP_API_CURRENTVERSION}`, 
        {clientID : clientID},
        await authorisationHeaders()
    )
    if(!client.data.body) 
      return { clientName : "Unknown",projects : []}

    if(client.data.body)
      return client.data.body


  }

  async getVirtualWorldForProjectID(projectID:string) {

    // http.headers.Authorization = this.getBearer();
    // DW:TODO:PROCESS.ENV
    let REACT_APP_API_PROJECTSLIST = 'projects';
    let REACT_APP_API_CURRENTVERSION = '0100';
    let REACT_APP_API_PROJECTVIRTUALWORLD = '/vworld';
    let request = await instance();
    let vWorld = await request.post(`${REACT_APP_API_PROJECTSLIST}/${REACT_APP_API_CURRENTVERSION}/${REACT_APP_API_PROJECTVIRTUALWORLD}`,
    {projectID : projectID},
    await authorisationHeaders())

    console.log('--')
    console.dir(vWorld)
    console.log('--')

    if(!vWorld.data.body)
      return { virtualWorld : { url : ''} }

    
    if(vWorld.data.body && vWorld.data.body[0])
      return vWorld.data.body[0];
  }
  
  // getAssetByReference(reference)
  // {
  //   return http.get(`/asset/ref/${reference}`)
  // }

  // async getAssetsById(assetIdList)
  // {
  //   let response = await http.post(`/asset/list`,assetIdList)
  //   return response.data
  // }

  // getRelatedResources(commonResourceTypeName, resourceId)
  // {
  //   return http.get(`/asset/related/${commonResourceTypeName}/${resourceId}`)
  // }

  // async create(data) {
  //   return await http.post("/asset", data);
  // }

  // async update(id, data) {
  //   return await http.put(`/asset/${id}`, data);
  // }

  // delete(id) {
  //   return http.delete(`/asset/${id}`);
  // }

  // deleteAll() {
  //   return http.delete(`/asset`);
  // }

  // findByTitle(title) {
  //   return http.get(`/asset?title=${title}`);
  // }
}

export default new ProjectsService();