import React from 'react'
import Projects from '../Projects/Projects';
import { Auth } from 'aws-amplify';
import {Container, CardColumns,Card,Col,Row, CardHeader, CardBody, CardText,  Button} from 'reactstrap';

import {IDashboardProps, DashState, TCurrentUser, TUserAttributes} from '../../types/IDashboardProps'

class Dashboard extends React.Component<IDashboardProps,DashState,{}> {
    

    // private onPageChange : Function;
    constructor(props : IDashboardProps)
    {
        super(props)
        this.state = {
            activePage : 0,
            currentUser : {
                attributes : { 
                    given_name : 'Not Logged In' ,
                    email : ''
                }
            }
        }

        this.onPageChange = this.onPageChange.bind(this)
        
    }

    componentDidMount = async () =>
    {
        
        let currentUser = await Auth.currentAuthenticatedUser({bypassCache: false})
            .then((user) => {
                return user;
            })
            .catch((err) => console.log(err));
        // console.log('=-=-=-=')
        // console.dir(currentUser)

        this.setState((prevState,props) => ({
            currentUser : currentUser ? currentUser : undefined
        }), () => console.dir(this.state.currentUser))
    }



    onPageChange = (pageNumber : number) =>
    {
        console.log("Page number is changing - " + pageNumber)
        
       
        switch(Number(pageNumber))
        {
            
            case 1:
                this.props.navigate("/projects");
                break;
            default:
                this.props.navigate("/");
                break;
            
        }
        
    }

    render = () => {
        
        return (
            <Container fluid>
                <Row>
                    <Col md={6} className="dashboardWelcomePanel">
                        Welcome {this.state.currentUser.attributes.given_name} [{this.state.currentUser.attributes.email}], <br/><br/>
                        <p>Please select from the available list of projects</p>
                    </Col>
                    <Col md={6}><Projects user={this.state.currentUser} /></Col>
                </Row>
                
            {/* <CardColumns className="customCardColumnCount">
                <Card className="lsCard" onClick={() => this.onPageChange('1')}>
                    <CardImg
                        top
                        className="lsCardImage"
                        src="/img/cardheader_packaging.png"
                        alt="Current Projects" />
                    
                    <CardHeader className="d-flex flex-row">
                        <div className="flex-fill"><strong>Current Projects</strong></div>
                        
                    </CardHeader>
                    <CardBody className="d-flex flex-column">
                        <CardText>
                            View our projects, interact in an immersive 3D environment.
                        </CardText>
                        <Button className="float-right mt-auto" color="danger" onClick={() => this.onPageChange('1')}>Current Projects</Button>
                    </CardBody>
                </Card>
                
                
            </CardColumns> */}
            </Container>           
        )
    }
}

// Dashboard.propTypes = {
    // user : PropTypes.string.isRequired
// }

export default Dashboard;