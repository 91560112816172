
import React, {Component} from 'react';
import ProjectsService  from '../../Integration/Services/ProjectsService'
import ProjectCard from './ProjectCard'
import {v4 as uuidv4} from 'uuid';
import {CardColumns, CardDeck } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default class Projects extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            client : [],
            clientProjects : []
        }
    }


   
    componentDidMount = async () => {
        let ps = ProjectsService;
        // DW:TODO:PROCESS.ENV
        let REACT_APP_CLIENTID_ONE = 'e4e82961-547c-45ce-9b19-7bf8dd0480a6';
        let client = await ps.getClientByClientID(REACT_APP_CLIENTID_ONE);
        
        this.setState((state,props) => ({
            client : client,
            clientProjects : client.projects
        }))
    }

    render() {
        console.log(`There are ${this.state.clientProjects.length} projects`)
        return (
            <CardDeck style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}} 
                      className="projectsCardColumns">
            {
                this.state.clientProjects.length > 0 && this.state.clientProjects.map ?
                
                    this.state.clientProjects.map( p => {
                        return <ProjectCard client={this.state.client} project={p} key={uuidv4()}/>
                    })
                 : 
                <Skeleton count="5" />
            }
            </CardDeck>
        )
    }

}