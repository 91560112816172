
import React from 'react';
import {AppProps} from './types/AppProps';
import {BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom'
import {Container} from 'reactstrap'


import Dashboard from './Components/Global/Dashboard'
import NavHeader from './Components/Global/NavHeader'
import Projects from './Components/Projects/Projects'
import ProjectSheet from './Components/Projects/ProjectSheet'
import Viewer from './Components/Viewer/Viewer'

import './App.scss';
// global.Buffer = global.Buffer || require('buffer').Buffer

function App({user, signOut } : AppProps) {
  const navigate = useNavigate();
  return (
    <div>
      <NavHeader user={user} signOut={signOut}/>

      <Container fluid>
        <Routes>
          <Route path="/" element={<Dashboard user={user} navigate={navigate}/>} />
          <Route path="/projects" element={<Projects user={user}/>} />
          <Route path="/project/:pid" element={<ProjectSheet user={user} />}  />
          <Route path="/project/:pid/viewer" element={<Viewer />} />
        </Routes>
      </Container>
    </div>
  );
}

export default App;
