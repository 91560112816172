import  React, {Component} from 'react';
import {Card, CardBody, CardFooter, CardImg, Badge,Col, Row, Button} from 'reactstrap'
import { withRouter } from '../Global/withRouter'
import ProjectsService from '../../Integration/Services/ProjectsService'

class ProjectSheet extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            projectID : '',
            project : {},
            virtualWorld : { url : '', params : {}}
        }
        this.launchVirtualWorld = this.launchVirtualWorld.bind(this)
    }

    fetchVirtualWorld = async () => {
        let virtualWorld = await ProjectsService.getVirtualWorldForProjectID(this.state.projectID);
        console.log(`Project Sheet`)
        this.setState((prevState,props) => ({
            virtualWorld : virtualWorld
        }))
    }

    launchVirtualWorld = () => {
        this.props.router.navigate(`/project/${this.state.projectID}/viewer`,{
            state:{ 
                projectID : this.props.router.location.state.projectID,
                project : this.props.router.location.state.project,
                client : this.props.router.location.state.client,
                clientID : this.props.router.location.state.clientID,
                virtualWorld : this.props.router.location.state.virtualWorld
            }
        })
    }

    componentDidMount = async () => {
        

        this.setState((prevState,props) => ({
            projectID : this.props.router.location.state.projectID,
            project : this.props.router.location.state.project,
            client : this.props.router.location.state.client,
            clientID : this.props.router.location.state.clientID,
            virtualWorld : this.props.router.location.state.virtualWorld
        }), () => console.dir(this.state))
    }

    render()
    {
        return (
            <Row className="d-flex align-items-center" >
                <Col md={7}>
                    <Card className="projectSheetCoverImage" >
                        {/* <CardImg    src="{project.coverImageUrl}"  */}
                        <CardImg    src="https://picsum.photos/318/180" 
                                    />
                        <CardFooter className="rightAlignFooter">
                            <Button onClick={this.launchVirtualWorld}>Launch</Button>
                        </CardFooter>
                    </Card>
                </Col>
                <Col md={5} className="d-flex flex-row">
                    <div className="panelSeparator"></div>
                
                    <div className="d-flex align-items-center projectSheetRightPanel">
                        {this.state.project.description}
                    </div>
                </Col>
            </Row>
            // <div>Project Sheet - {this.state.project.projectTitle}</div>
        )
    }
}

export default withRouter(ProjectSheet)